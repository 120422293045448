import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import styled from "styled-components";
import {DownloadSection} from "../components/downloadSection";

const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  position: relative;
  
  @media(min-width: 576px){
    position: fixed;
    top: 0; 
    left: 0;
    right: 0;
    
    height: 100%;
  }
`

const SideImage = styled(GatsbyImage)`

width: 100%;
  margin: 1rem 0;
  max-width: 576px;
object-fit: contain;
  
`

const Content = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid #8f8f8f;

  background-color: #fff;

  padding: 2rem;

  @media (min-width: 576px) {
    margin-top: 3rem;
    min-height: 60vh;
  }
  
  
`


const IndexPage = ({ data }) => (

  <Layout>
    <Seo title="Home" />
        <HeaderImage image={data.topSectionImage.childImageSharp.gatsbyImageData} alt="Desert and mountains of Eastern Ethiopia near Somalia" />
        <Content>
            <h1>English In Puntland</h1>
            <p>
                <strong>Puntland and Somalia</strong>. Goobta www: http://su-aalo.info
            </p>
            <p>
                Christianity existed along the <a href="https://www.jstor.org/stable/182543">Horn of Africa</a>, prior to Islam. <a href="https://en.wikipedia.org/wiki/Puntland">Puntland</a> is the word for a bridge, and centuries before Islam became the official religion of Somalia <em>bridge-land</em> or the gateway to southern Africa, was a country with some African followers of <span alt="In the Qur’an, Jesus is known as Issa, but the name Christians use is Yasuah or Yasuah al-Massiah.">Jesus</span>.
            </p>

            <h2>Muslims will know what is a Muslim (a follower of Allah, in the tradition of Muhammad).</h2>
            <p>Somali ahaan waxaan ognahay Muslimku inuu yahay ruuxa qira Shaahaadada:<span title="The Shahaada or Creed used by Muslims. The words are given in Arabic, see below. “There is no god but Allah and Muhammad is the messenger of Allah.”"> La Ilaha il Ilaah Moxamed rasuulu Ilaah</span></p>
            <GatsbyImage style={{ objectFit: 'contain' }} image={data.phraseOne.childImageSharp.gatsbyImageData} alt="Desert and mountains of Eastern Ethiopia near Somalia" />
            <p>
            </p>
            <p>
                <strong>But do Muslims know “What is a follower of Issa?”</strong> The words in Somali, in Af-Somali, are: Laakiin Muslimka sideebuu kaga duwan yahay
                kuwa masiixiga ah?
            </p>
            <p>
                Some years ago (in 2010) a follower of Jesus visited Bossasso, the sea port on the Horn of Africa to find out if he or some of his friends could help the people of Puntland, by teaching English to students there or at the University of <a href="https://eaugarowe.edu.so/">Garoowe</a>. Garoowe, the capital of Puntland, is the administrative capital of Puntland in northeastern Somalia. (Wikipedia: Arabic: غاروي, Italian: Garoe)
            </p>
            <p>
                There is a complex or campus, and an impressive <em>University Library</em>, and the Ministry of Education has its building in Garoowe.
            </p>
            <figure>
            <SideImage style={{ objectFit: 'contain' }} image={data.sign.childImageSharp.gatsbyImageData} alt="Desert and mountains of Eastern Ethiopia near Somalia" />

                <figcaption><p><i>The outside wall of the <em>Ministry of Education</em>, Garoowe</i></p></figcaption>
            </figure>

            <p>
                Muslim friends were very kind to this visitor. While staying there he asked the Professor, <i>“If I come and teach English to your students, and if it is obvious to them that I am not myself a Muslim, if asked by a student why are you not a Muslim, can I reply and explain why not? The professor said: “That would be difficult”.</i>
            </p>
            <p>
                This visitor asked one of his local friends in Garoowe, “are you aware the Holy Book <em>Kitab-al Muquddisa-ah</em> is the Torah, the Zabour and the Injil, in the Somali language. There is a revised translation in the Somali language. Could a copy of this holy book be allowed into the university library?” The reply given was that if a copy of this a book were placed here, the building would be burnt down. (<span style={{textDecoration:'underline'}}>No, this knowledge of Christianity is not permitted</span>.)
            </p>
            <p>
                <strong>So, what then is a Christian?</strong> And why is that book not permitted in Puntland? John Chapman from Australia wrote a booklet with the title: “What is a Christian?” In Nairobi, Kenya, this small publication was translated into Somali, and the text was enlarged to help explain this subject to people with the culture and belief system of Somalis. In the booklet, an elucidation is given that Issa or Jesus said he existed <em>before</em> Abraham existed. The words in Arabic are:
            </p>
            <p>
                Ciise ma uu billaaban oo kaliya markii ay Maryan dhashay Isaga. Ciise wuxuu jiray Ibraahim ka hor, oo waxay ahayd sanaddo badan ka hor. Ciise ayaa madaxdii wadaddadii “ku yiri Ibraahim hortiis ayaan jiray.
            </p>
            <SideImage style={{ objectFit: 'contain' }}  image={data.phraseTwo.childImageSharp.gatsbyImageData} alt="Desert and mountains of Eastern Ethiopia near Somalia" />

<DownloadSection Image={data.coverMockup.childImageSharp.gatsbyImageData} />
            <p>
                <strong>There is a difference between education and indoctrination.</strong>
            </p>
            <p>
                To explain, education may require the approach of Socrates, a Greek philosopher and teacher; a man <strong>who used questions to provide a good teaching outcome for his students</strong>. In the realm of <em>Din</em> or <em>religion</em>, a capable teacher will ask: what answers do followers of Ciise give about Christianity, to explain why they dare to call “Issa or Yasuah the son of God.” (Since Allah cannot have a son, as the Qur’an declares).
            </p>
            <p>
                <strong>In Puntland, it may be important to know these answers</strong>. The words translated by su-aalo.info explain this. As a man, Jesus said: ‘I am the door.’ But Jesus is not a wooden door on a hinge. He was speaking philosophically, in a way that explains what he <em>does</em>, if people are to come to him. It does not explain what he looks like.
            </p>
            <p>
                In a similar way, Christianity mentions that Yasuah (Issa) is the ‘Son of God.’ It is true that the Bible (the Holy Book <em>Kitab-al Muquddisa-ah</em>) calls Jesus Christ the “Son of God” but please note one thing. He is not called “Walad Allah” You will know that the Qur’an reports on the <em>Times of Ignorance</em>, and on the view then that Allah had some daughters. But daughters He could not have, for that is not possible. God has no wife, and it is blasphemy to say that He has “daughters”.
            </p>
            <p>
                Similarly, followers of Jesus say, He God has no “walad” or boy. You will not find the expression Waladu’llah used of Jesus Christ. But the expression Ibnu’llaah is used because it does not require or teach that God had sex with Mary and so made a ‘son’ in a human, sexual or biological sense.
            </p>
            <p>
                In the Gospel (the Injil), Jesus is called by many terms such as “the door” and “the vine” and “the way.” But he is not made of wood, nor does He grow like a vine, or swing on a hinge like any door in your house. No, this is a philosophical way of using words. He is called the “Son of God” because He is of one Nature or Substance with the Father, just as my earthly son has my nature, or my clan flesh.
            </p>
            <h2>Af-Somali</h2>
            <p>
                Waa run in Kitaabka uu Ciise uu ugu yeeray Masiixa “Willka Ilaah” laakkiin waxaan idnka codsanaa inaad hal mid ogaataan. Looguma yeerin “Walad Allah” taasoo marar badan lagu sheegay Quraanka, iyo xitaa in Ilaah uu leeyahay ilmo ah gabdho. Laakiin runtii ma lahayn gabdho iyo wiilal mana aha wax macquula ah. Ilaah ma aha mid naag guursaday waa na shirki in taas la yiraahdo ama loogu yeero inuu dhalay gabdho. Sidoo kale kuwa Ciise raacay “ayaa yiri” ma laha ama dhalin Walad ama Will midna. Ma jiro qof heli karaa micnaynta ah Waladu Allah oo loo isticmaalay Ciise Masiix.
            </p>
            <p>
                Laakiin sabadta Ciise loogu yeeray Ina Ilaah macnaheeda ma aha in Ilaah uu Maryan guursaday oo markaas ay dhashay Will ah bani-aadam, ama xagga jirka ka yimid. Injiilka, Ciise waxaa loogu yeeray magacyo badan kuwaasoo ay ka mid yihiin Albaabka,
            </p>
            <p>
                Geedka canabka laakiin Isagama samayn alwaaxa oo mana u korin sidii geed oo kale ama sidii albaab guri xiran oo kale.
            </p>
            <p>
                Laakiin erayadan waxaa loo isticmaalay hab cilmiyeed. W axaa loogu yeeray Ciise “Wiillka Ilaah” maxaa yeelay waa mid xagga dabeecadda la mid ah Ilaah ah Aabbaha, sida wiilkayga aan dhalay uu iila mid yahay amaba qabiilkayga aan isugu mid nahay sidaas oo kale ayay isugu mid yihiin Aabbaha iyo Willka.
            </p>

            <figure>
                <SideImage style={{ objectFit: 'contain' }} image={data.university.childImageSharp.gatsbyImageData} alt="Desert and mountains of Eastern Ethiopia near Somalia" />

                <figcaption>
                    <p>
                        <i>Puntland State of Somalia. Teachers College</i>
                    </p>
                    <p>
                        <i>Kullayadda Macallimiinta Garoowe</i>
                    </p>
                </figcaption>
            </figure>


            <p>
                We learn that:
            </p>

            <p>
                <strong>Education is by asking sensible questions</strong>
            </p>
            <p>
                This is why Puntland is superior. Its university education is excellent.
            </p>
            <p>
                Thank you for viewing this web page. <strong>Mahadsanid.</strong>
            </p>

            <DownloadSection Image={data.coverMockup.childImageSharp.gatsbyImageData} />

        </Content>
  </Layout>
)

export const Head = () => <Seo title="Home" />

export default IndexPage

export const pageQuery = graphql`
  query {
    topSectionImage: file(relativePath: {eq: "somalia.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          layout: CONSTRAINED
        )
      }
    }
    phraseOne:file(relativePath: {eq: "phrase-1.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          layout: FULL_WIDTH
        )
      }
    }
    phraseTwo:file(relativePath: {eq: "phrase-2.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          layout: FULL_WIDTH
        )
      }
    }
    
    sign:file(relativePath: {eq: "sign.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          layout: CONSTRAINED
        )
      }
    }
    university:file(relativePath: {eq: "university.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          layout: CONSTRAINED
        )
      }
    }
    coverMockup:file(relativePath: {eq: "ebook-cover.png"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          layout: CONSTRAINED
        )
      }
    }
  }
`
