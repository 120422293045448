import React from "react";
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";


const Wrapper = styled.div`
    display: flex;
  background-color: #d18f59;
  margin: 2rem 0;

`

const Container = styled.div`
  display: flex;
  max-width: 900px;
  margin: auto;
  flex-direction: column;
  padding: 1rem;

  @media(min-width: 768px){
    flex-direction: row;
    padding: 2rem;
  }
  
  .text{
    width: calc(100% - 2rem);
    margin: 0 auto;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: left;

    .download-btn{
      background-color:#fff;
      color: #d18f59;
      border-color: #d18f59;
      border:1px solid;
      border-radius: 5px;
      padding: 1rem 2rem;
      margin: 4rem 0;
display: block;
      text-align: center;
      text-decoration: none;
      font-size: 1rem;
      
      :hover{
        background-color:#d18f59;
        color: #fff;
        border-color: #fff;
        
      }
    }

    @media(min-width: 768px){
      width: 66%;
    }
    
  }
  .image{
    width: calc(100% - 2rem);
    margin: 0 auto;
    
    @media(min-width: 768px){
      width: 33%;
    }

  }
`


export const DownloadSection = ({ Image }) => {
    return (
        <>
            <Wrapper>
                <Container>
                    <div className="text">
                        To read the whole of the booklet by John Chapman and Abu Xabiib Yahya, simply click the link below to download the eBook for free.
                        <a className="download-btn"
                           href="https://www.englishinpuntland.com/downloads/What%20is%20a%20true%20follower%20of%20Jesus%20Christ-WEB.pdf">CLICK
                            HERE TO DOWNLOAD</a>
                    </div>
                    <GatsbyImage className="image" image={Image} alt="smiling female student" />
                </Container>
            </Wrapper>
        </>
    )
}

